* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

